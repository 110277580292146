import { Component } from '@angular/core';
import { ContactUsModalComponent } from '../../../shared/components/_modals/contact-us-modal/contact-us-modal.component';
import { Platform } from '../../../shared/enums/generated.enums';
import { DownloadHelper } from '../../../shared/helpers/download.helper';
import { ApiService } from '../../../shared/services/api/api.service';
import { LoggingService } from '../../../shared/services/logging.service';
import { ModalService } from '../../../shared/services/modal.service';
import { OrganizationService } from '../../../shared/services/organization.service';
import { PopupService } from '../../../shared/services/popup.service';

@Component({
  selector: 'app-rpa-not-connected',
  templateUrl: './rpa-not-connected.component.html',
  styleUrls: ['./rpa-not-connected.component.scss'],
})
export class RpaNotConnectedComponent {
  constructor(
    private modalService: ModalService,
    private organizationService: OrganizationService,
    private apiService: ApiService,
    private popupService: PopupService,
    private loggingService: LoggingService,
  ) {}

  getPlatformString(): string {
    if (this.hasUiPathPlatform()) {
      return 'UiPath';
    } else if (this.hasBluePrismPlatform()) {
      return 'Blue Prism';
    } else if (this.hasAutomationAnywherePlatform()) {
      return 'Automation Anywhere';
    } else {
      return 'Manual processing';
    }
  }

  hasUiPathPlatform(): boolean {
    return this.organizationService.hasPlatform(Platform.UiPath);
  }

  hasBluePrismPlatform(): boolean {
    return this.organizationService.hasPlatform(Platform.BluePrism);
  }

  hasAutomationAnywherePlatform(): boolean {
    return this.organizationService.hasPlatform(Platform.AutomationAnywhere);
  }

  openContactSupportModal(): void {
    this.modalService.open(ContactUsModalComponent);
  }

  downloadUiPathManual(): void {
    DownloadHelper.download('../../../assets/Pointee-UiPath Integration Guide.pdf', 'Pointee-UiPath Integration Guide');
  }

  downloadBluePrismApp(): void {
    try {
      this.apiService.importApi.downloadInstaller();
    } catch (error) {
      this.loggingService.logError('Failed to download data utility.', error);
      this.popupService.showSnackBar('Failed to download data utility.');
    }
  }
}
