import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { OrganizationService } from '../services/organization.service';
import { SignalRService } from '../services/signalr.service';

@Injectable({ providedIn: 'root' })
export class MainAuthGuard {
  constructor(
    private authService: AuthService,
    private organizationService: OrganizationService,
    private signalRService: SignalRService,
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    await this.organizationService.initializeOrganizationsIfNeeded();
    if (!this.authService.isLoggedIn) {
      return false;
    }
    await this.signalRService.startConnection();
    return true;
  }

  async canDeactivate(): Promise<boolean | UrlTree> {
    await this.signalRService.stopConnection();
    await this.authService.logout();
    return true;
  }
}
