import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { PageType } from '../enums/page-type.enum';

@Injectable({ providedIn: 'root' })
export class RedirectToDefaultAuthGuard {
  constructor(
    private navigationService: NavigationService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const organizationDefaultPage = this.navigationService.getDefaultPageRoute;
    if (organizationDefaultPage) {
      await this.navigationService.navigateToDefaultPage(true);
      return false;
    }

    // If no dynamic path is determined, navigate to a default route or show a 404 page
    this.router.navigate([PageType.NotFound], { replaceUrl: true });
    return true;
  }
}
