import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalDialogDirective } from 'src/app/shared/directives/base-modal-dialog.directive';
import { ModalSize } from 'src/app/shared/enums/modal-size.enum';
import { LoadingScreenService } from 'src/app/shared/services/loading-screen.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { OrganizationName } from 'src/app/shared/types/organization-name.type';
import { ModalCloseType } from '../../shared/components/modal/modal-close-type.enum';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSelectComponent extends BaseModalDialogDirective {
  organizationFilter: string[];

  get size(): ModalSize {
    return ModalSize.Large;
  }

  get closeType(): ModalCloseType {
    return ModalCloseType.Normal;
  }

  get organizations(): { name: string; label: string }[] {
    return this.organizationService.organizations;
  }

  get selectedOrganization(): OrganizationName {
    return this.organizationService.selectedOrganization;
  }

  constructor(private organizationService: OrganizationService, private navigationService: NavigationService, private loadingScreenService: LoadingScreenService) {
    super();
  }

  public applyFilter(filterValue: string): void {
    this.organizationFilter = [filterValue.trim().toLowerCase()]; // Remove whitespace & to lowercase
  }

  public async selectOrganization(organizationName: string): Promise<void> {
    this.loadingScreenService.showLoadingScreen();
    this.close();
    try {
      await this.navigationService.navigateToOtherOrganization(organizationName);
    } finally {
      this.loadingScreenService.hideLoadingScreen();
    }
  }

  public isSelected(organizationName: string): boolean {
    return this.selectedOrganization && this.selectedOrganization.name === organizationName;
  }
}
