import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { DataAvailabilityService } from '../services/data-availability.service';

export function checkRpaConnection(): CanActivateFn {
  return async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const navigationService = inject(NavigationService);
    const dataAvailabilityService = inject(DataAvailabilityService);
    const dataAvailability = await dataAvailabilityService.getDataAvailability();
    if (!dataAvailability.isValid) {
      await navigationService.navigateToRpaNotConnected();
      return false;
    }

    return true;
  };
}
