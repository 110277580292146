<!-- CONTENT -->
<div class="main" [attr.theme-layout]="themeLayout" [attr.device-type]="deviceType" [attr.layout-type]="layoutType" (window:resize)="onResize($event)">
  <app-loading-screen></app-loading-screen>
  <div class="main-container">
    <app-menu></app-menu>
    <div class="main-body">
      <app-alert-bar></app-alert-bar>
      <app-breadcrumbs *ngIf="showBreadcrumb"></app-breadcrumbs>
      <div class="page-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>

    <!-- SIDE PANEL -->
    <mat-sidenav-container [autosize]="sidenavSettings.autosize" [hasBackdrop]="sidenavSettings.hasBackdrop" (backdropClick)="sidenavClose()" disableClose>
      <mat-sidenav #sidepanel (keydown.escape)="sidenavClose()" class="side-panel" [ngClass]="sidenavSettings.size" disableClose position="end">
        <mat-sidenav-content class="side-panel-content">
          <button
            type="button"
            svgIcon="close"
            class="btn btn-icon-only sidenav-close-button"
            *ngIf="sidenavSettings.hasCloseButton || deviceType === DeviceType.phone"
            (click)="sidenavClose()"
          ></button>
          <ng-container #sidepanelContent></ng-container>
        </mat-sidenav-content>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>
</div>

<app-upload-processing></app-upload-processing>
