import { Component, OnInit } from '@angular/core';
import { ContactUsModalComponent } from 'src/app/shared/components/_modals/contact-us-modal/contact-us-modal.component';
import { AlertSeverity } from 'src/app/shared/enums/alert-severity.enum';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { DataAvailabilityService } from './../../shared/services/data-availability.service';
import { DataAvailabilityInfo } from './../../shared/types/data-availability-info.type';

@Component({
  selector: 'app-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
})
export class AlertBarComponent implements OnInit {
  readonly AlertSeverity = AlertSeverity;

  dataAvailability: DataAvailabilityInfo;

  constructor(
    public notificationService: NotificationsService,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private dataAvailabilityService: DataAvailabilityService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.dataAvailability = await this.dataAvailabilityService.getDataAvailability();
  }

  openContactSupportModal(): void {
    this.modalService.open(ContactUsModalComponent);
  }

  async navigateByRoute(route: string[]): Promise<void> {
    await this.navigationService.navigateByRoute(route || ['']);
  }
}
