import { Component } from '@angular/core';

@Component({
  selector: 'app-default-loading-page',
  standalone: true,
  imports: [],
  templateUrl: './default-loading-page.component.html',
  styleUrl: './default-loading-page.component.scss',
})
export class DefaultLoadingPageComponent {}
